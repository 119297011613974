<template>
  <T3Link class="nav-social-link" :to="getLink(link)">
    <UiImg :image="link.icon" />
  </T3Link>
</template>

<script>
import { UiImg } from '~ui/components'

export default {
  components: { UiImg },
  props: {
    link: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getLink({ link, target, title }) {
      return { link, target, title }
    }
  }
}
</script>

<style lang="scss">
.nav-social-link {
  height: 44px;
  width: 44px;
  display: grid;
  place-items: center;
  background-color: var(--bg-200);
  border-radius: em(2px);

  & > img {
    height: 20px;
    width: 20px;
    filter: invert(16%) sepia(18%) saturate(5239%) hue-rotate(173deg)
      brightness(91%) contrast(95%);
  }
}
</style>
